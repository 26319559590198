import React from 'react'
import { prepareParagraph, sanityImageUrl } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ values }) => {
  if (!values || values.isHide) return null

  return (
    <div className={styles.values}>
      <div className={styles.wrap}>
        <div className={styles.text}>
          <h2 className={styles.title}>{values.title}</h2>
          <div className={styles.items}>
            {values.items && values.items.map(value => (
              <div className={styles.item} key={value._key}>
                <img className={styles.image} src={sanityImageUrl(value.image)} alt={value.image?.caption} />
                <img className={styles.imageMobile} src={sanityImageUrl(value.imageMobile)} alt={value.imageMobile?.caption} />
                <div className={styles.top}>
                  <p className={styles.head}>{value.title}</p>
                  <p className={styles.description}>{value.description}</p>
                </div>
                <ul className={styles.list}>
                  {value.list && value.list.map((item, index) => (
                    <li className={styles.listItem} key={index}>{prepareParagraph(item)}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}