import React from 'react'
import { prepareParagraph } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ about }) => {
  if (!about || about.isHide) return null

  return (
    <div className={styles.about}>
      <div className={styles.wrap}>
        <div className={styles.text}>
          <h2 className={styles.title}>{about.title}</h2>
          <p className={styles.description}>{prepareParagraph(about.text)}</p>
        </div>
      </div>
    </div>
  )
}
